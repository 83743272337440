/* eslint-disable */
import { configureStore, combineReducers } from "@reduxjs/toolkit";

import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage";
import eventReducer from "./slices/event";
import uiReducer from "./slices/ui";
import chatUISlice from "./slices/chat-ui";
import callsUISlice from "./slices/calls-ui";
import botUISlice from "./slices/bot-ui";
import botsReducer from "./slices/botsSlice";
import customerList from "./slices/customerList";
import venuesReducer from "./slices/venuesSlice";

const persistConfig = {
  key: "doar",
  version: 1,
  storage,
};

export const rootReducer = combineReducers({
  events: eventReducer,
  ui: uiReducer,
  chatUI: chatUISlice,
  callsUI: callsUISlice,
  botUI: botUISlice,
  bots: botsReducer,
  customerList: customerList,
  venueList: venuesReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
