/* eslint-disable */
import { AppDispatch } from "../store";
import { loadCustomerListStart, loadCustomerListSuccess, loadCustomerListFailure } from "../slices/customerList";
import API from "@doar/shared/methods/api"; 

export const fetchCustomerList = () => async (dispatch: AppDispatch) => {
  dispatch(loadCustomerListStart());
  let customersAllData: any = [];
  const customersUrl = `/customers`;

  try {
    let initialResponse = await API.get(`${customersUrl}?offset=0`);
    const totalCustomers = initialResponse.data.pagination.total;
    const pageSize = initialResponse.data.pagination.limit; 

    customersAllData = [...initialResponse.data.data];
    let customersOffset = customersAllData.length;

    if (customersAllData.length >= totalCustomers) {
      window.sessionStorage.setItem("customerList", JSON.stringify(customersAllData));
      return;
    }

    let promises = [];
    while (customersOffset < totalCustomers) {
      promises.push(API.get(`${customersUrl}?offset=${customersOffset}`));
      customersOffset += pageSize; 
    }

    let results = await Promise.all(promises);
    results.forEach((result) => {
      customersAllData = [...customersAllData, ...result.data.data];
    });

    dispatch(loadCustomerListSuccess(customersAllData));
  } catch (error) {
    console.error(error);
    dispatch(loadCustomerListFailure("Error al cargar lista de customers"));
  }
};
