/* eslint-disable */
import { Navigate } from "react-router-dom";

interface IProps {
    alsoPartner?: boolean;
}

const OnlyAdminRoute = ({
    children,
    alsoPartner,
}: {
    children: JSX.Element;
    alsoPartner?: boolean;
}) => {
    const papiUserJSON = sessionStorage.getItem("papiUser");
    let papiUser = null;

    if (papiUserJSON) {
        papiUser = JSON.parse(papiUserJSON);
    }

    let authed = papiUser?.user?.role === "ADMIN";
    if (alsoPartner) {
        if (papiUser?.user?.role === "PARTNER") {
            authed = true;
        }
    }

    return authed ? children : <Navigate to="/billing" />;
};

export default OnlyAdminRoute;
