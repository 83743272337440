/* eslint-disable */
import { useEffect } from "react";

declare global {
  interface Window {
    respondIoWidget?: {
      setUser: (userData: { id?: string; name?: string; email?: string; phone?: string; customFields?: Record<string, any> }) => void;
    };
  }
}

interface UserType {
  user: {
    auth_uid: string;
    email: string;
    id: string;
    name: string;
    permissions: any[];
    phone: { number?: string };
    role: string;
    sip_settings: object;
    language: string;
    allows_marketing: boolean;
  };
  customers: any[];
}

const RespondIoChat = ({ user }: { user?: any }) => {
  useEffect(() => {
    if (window.respondIoWidget && user?.user) {
      window.respondIoWidget.setUser({
        id: user.user.id || "guest",
        name: user.user.name || "Invitado",
        email: user.user.email || "guest@example.com",
        phone: user.user.phone?.number || "",
        customFields: {
          role: user.user.role || "CLIENT",
          language: user.user.language || "UNKNOWN",
          allows_marketing: user.user.allows_marketing ? "YES" : "NO",
        },
      });
    }

    const script = document.createElement("script");
    script.id = "respondio__widget";
    script.src = "https://cdn.respond.io/webchat/widget/widget.js?cId=af936fdd03430121dd9d710f543b6410b83482f3ea85c4029c07881e8cd22bc8";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.getElementById("respondio__widget")?.remove();
    };
  }, [user]);

  return null;
};

export default RespondIoChat;
