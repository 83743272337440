/* eslint-disable */
import { AppDispatch } from "../store";
import { loadBotsStart, loadBotsSuccess, loadBotsFailure } from "../slices/botsSlice";
import API from "@doar/shared/methods/api";

export const fetchBots = () => async (dispatch: AppDispatch) => {
  dispatch(loadBotsStart());

  const botsUrl = `/bots/ids_names`;

  try {
    let response = await API.get(`${botsUrl}?offset=0`);
    let botsData = response.data.data;

    const totalBots = response.data.pagination.total;
    const pageSize = response.data.pagination.limit;

    let botsOffset = botsData.length;
    let promises = [];

    while (botsOffset < totalBots) {
      promises.push(API.get(`${botsUrl}?offset=${botsOffset}`));
      botsOffset += pageSize;
    }

    let results = await Promise.all(promises);
    results.forEach((result) => {
      botsData = [...botsData, ...result.data.data];
    });

    dispatch(loadBotsSuccess(botsData));
  } catch (error) {
    console.error("❌ Error obteniendo los bots desde la API:", error);
    dispatch(loadBotsFailure("Error al cargar los bots"));
  }
};
