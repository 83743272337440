/* eslint-disable */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Bot {
  id: string,
  name: string
}

interface BotsState {
  bots: Bot[];
  loading: boolean;
  error: string | null;
}

const initialState: BotsState = {
  bots: [],
  loading: false,
  error: null,
};

const botsSlice = createSlice({
  name: "bots",
  initialState,
  reducers: {
    loadBotsStart(state) {
      state.loading = true;
      state.error = null;
    },
    loadBotsSuccess(state, action: PayloadAction<Bot[]>) {
      state.bots = action.payload;
      state.loading = false;
    },
    loadBotsFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { loadBotsStart, loadBotsSuccess, loadBotsFailure } = botsSlice.actions;

export default botsSlice.reducer;
