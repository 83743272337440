/* eslint-disable */
import React, { useContext, useEffect, useRef, useState } from "react";
import { Search } from "react-feather";
import { UserContext } from "src/config/userContext";
import { StyledForm } from "./style";
import "./styles.css";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import { useAppDispatch } from "src/redux/hooks";
import { fetchBots } from "src/redux/actions/botsActions";
import { fetchCustomerList } from "src/redux/actions/customerListActions";

interface IProps {
  getInput?: any;
  toggleSearch: (value: boolean) => void;
}

const ContentSearch: React.FC<IProps> = (props) => {
  const { getInput, toggleSearch } = props;
  const [showSearch, setShowSearch] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState("");
  const dispatch = useAppDispatch();

  const { customers, loading } = useSelector((state: RootState) => state.customerList);
  const { bots } = useSelector((state: RootState) => state.bots); 

  const [botsLoaded, setBotsLoaded] = useState(false);
  const [customersLoaded, setCustomersLoaded] = useState(false);

  function handleSubmit(e: any) {
    e.preventDefault();
  }

  function handleChange(e: any) {
    const value = e.target.value;
    setInputValue(value); 
    getInput(value); 
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.metaKey && event.key === ".") {
        event.preventDefault();
        setShowSearch((prev) => !prev);
        toggleSearch(!showSearch);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (showSearch && !customersLoaded) {
      dispatch(fetchCustomerList()); 
      setCustomersLoaded(true); 
    }

    if (showSearch && !botsLoaded) {
      dispatch(fetchBots()); 
      setBotsLoaded(true); 
    }
  }, [showSearch, botsLoaded, customersLoaded, dispatch]);

  useEffect(() => {
    if (showSearch && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showSearch]);

  const filterBots = (value: string) => {
    if (value.length > 0) {
      const filteredBots = bots.filter(
        (bot: any) =>
          bot.id.toLowerCase().includes(value.toLowerCase()) ||
          bot?.name.toLowerCase().includes(value.toLowerCase())
      );
      return filteredBots.map((bot: any) => `${bot.id} (${bot.name})`);
    }
    return [];
  };

  return (
    <>
      {showSearch && <div className="content-search-overlay" onClick={() => setShowSearch(false)} />}
      {showSearch && (
        <div className="content-search-wrapper">
          <StyledForm onSubmit={handleSubmit} className="search-input-header">
            <Search strokeWidth="2.8px" size={20} />
            <input
              type="text"
              id="search-new"
              name="search-new"
              placeholder="Search..."
              onChange={handleChange}
              ref={inputRef}
            />
          </StyledForm>
          <div className="search-results">
            {filterBots(inputRef.current?.value || "").map((bot: string, index: number) => (
              <div key={index}>{bot}</div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ContentSearch;
