/* eslint-disable */
import { Navigate } from "react-router-dom";

interface IProps {
  alsoPartner?: boolean;
}

const OnlyAdminAndBDP = ({ children, alsoPartner }: { children: JSX.Element; alsoPartner?: boolean }) => {
  const papiUserJSON = sessionStorage.getItem("papiUser");
  const papiCustomerJSON = sessionStorage.getItem("customerSelected");
  let papiUser = null;
  let custSelected = null;

  if (papiUserJSON) {
    papiUser = JSON.parse(papiUserJSON);
  }
  if (papiCustomerJSON) {
    custSelected = JSON.parse(papiCustomerJSON);
  }

  let authed = papiUser?.user?.role === "ADMIN" || custSelected?.id === "bdp";

  return authed ? children : <Navigate to="/billing" />;
};

export default OnlyAdminAndBDP;
