/* eslint-disable */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface VenuesState {
  venueList: any[]; // Cambié venues a venueList
  loading: boolean;
  error: string | null;
}

const initialState: VenuesState = {
  venueList: [], // Asegúrate de que venueList se inicialice como un array vacío
  loading: false,
  error: null,
};

const venuesSlice = createSlice({
  name: "venueList", // Cambié el nombre aquí a 'venueList' para mayor consistencia
  initialState,
  reducers: {
    loadVenuesStart(state) {
      state.loading = true;
      state.error = null;
    },
    loadVenuesSuccess(state, action: PayloadAction<any[]>) {
      state.venueList = action.payload; // Aquí asignamos el payload a venueList
      state.loading = false;
    },
    loadVenuesFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
    },
    deleteVenuesRedux(state, action: PayloadAction<string[]>) {
      state.venueList = state.venueList.filter((venue) => !action.payload.includes(venue.id));
    },
    addVenueRedux(state, action: PayloadAction<any>) {
      state.venueList.push(action.payload); 
    },
  },
});

export const { loadVenuesStart, loadVenuesSuccess, loadVenuesFailure, addVenueRedux, deleteVenuesRedux } = venuesSlice.actions;

export default venuesSlice.reducer;
