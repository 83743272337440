/* eslint-disable */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./translations/en.json";
import es from "./translations/es.json";
import ca from "./translations/ca.json";
import it from "./translations/it.json";
import pt from "./translations/pt.json";
import fr from "./translations/fr.json";
import de from "./translations/de.json";

i18n.use(initReactI18next).init({
  lng: localStorage.getItem("selectedLanguage") || "es",
  fallbackLng: localStorage.getItem("selectedLanguage") || "es",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    ENGLISH: { translation: en },
    SPANISH: { translation: es },
    CATALAN: { translation: ca },
    ITALIAN: { translation: it },
    PORTUGUESE: { translation: pt },
    FRENCH: { translation: fr },
    GERMAN: { translation: de },
  },
});

export default i18n;
