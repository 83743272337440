/* eslint-disable */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Customer {
  id: string;
  name: string;
}

interface CustomerListState {
  customers: Customer[];
  loading: boolean;
  error: string | null;
}

const initialState: CustomerListState = {
  customers: [],
  loading: false,
  error: null,
};

const customerListSlice = createSlice({
  name: "customerList",
  initialState,
  reducers: {
    loadCustomerListStart(state) {
      state.loading = true;
      state.error = null;
    },
    loadCustomerListSuccess(state, action: PayloadAction<Customer[]>) {
      state.customers = action.payload;
      state.loading = false;
    },
    loadCustomerListFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  loadCustomerListStart,
  loadCustomerListSuccess,
  loadCustomerListFailure,
} = customerListSlice.actions;

export default customerListSlice.reducer;
